// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName } from '@thatworks/connector-api';

export interface MetricsUser {
    userId: string;
    emailDomain: string;
    organizationId: string;
    ip?: string;
    userAgent?: string;
}

export enum Gates {
    CreateUserForm = 'app_create_user_form',
    FrontendShowDataSources = 'frontend_show_data_sources',
}

export enum Events {
    ReportEmailOpened = 'report_email_opened',
    ReportEmailDelivered = 'report_email_delivered',
    FrontendOnboardWelcomePageLoaded = 'frontend_onboard_welcome_page_loaded',
    FrontendConnectAppsPageLoaded = 'frontend_connect_apps_page_loaded',
    FrontendConnectStatusPageLoaded = 'frontend_connect_status_page_loaded',
    FrontendNewConnectionModalOpened = 'frontend_new_connection_modal_opened',
    FrontendRemoveConnectionModalOpened = 'frontend_remove_connection_modal_opened',
    BackendConnectionAdded = 'backend_connection_added',
    BackendConnectionDeleted = 'backend_connection_deleted',
    FrontendWorkspaceCreatePageLoaded = 'frontend_workspace_create_page_loaded',
    BackendWorkspaceCreated = 'backend_workspace_created',
    BackendWorkspaceDeleted = 'backend_workspace_deleted',
    FrontendComposerOpened = 'frontend_composer_opened',
    FrontendComposerClosed = 'frontend_composer_closed',
    FrontendComposerPublished = 'frontend_composer_published',
    BackendPostCreated = 'backend_post_created',
    BackendPostDeleted = 'backend_post_deleted',
    BackendPostsFetched = 'backend_posts_fetched',
    BackendPostTemplateCreated = 'backend_template_created',
    BackendPostTemplatesFetched = 'backend_templates_fetched',
    BackendPostTemplateDeleted = 'backend_template_deleted',
    BackendTemplateAutomationCreated = 'backend_template_automation_created',
    BackendTemplateAutomationDeleted = 'backend_template_automation_deleted',
    BackendTemplateAutomationsFetched = 'backend_template_automations_fetched',
    BackendTemplateAutomationProcessed = 'backend_template_automation_processed',
    FrontendComposerSummaryButtonClicked = 'frontend_composer_summary_button_clicked',
    FrontendComposerSummaryGenerated = 'frontend_composer_summary_generated',
    BackendSlackMessageActionClicked = 'backend_slack_message_action_clicked',
    BackendSlackMessagePosted = 'backend_slack_message_posted',
    BackendSlackMessageReadMorePostFetch = 'backend_slack_message_read_more_post_fetched',
    BackendSlackMessageItemUrlRedirected = 'backend_slack_message_item_url_redirected',
    BackendSlackExpandInitModalCreated = 'backend_slack_expand_init_modal_created',
    BackendSlackExpandDataModalCreated = 'backend_slack_expand_data_modal_created',
}

export enum DynamicConfigs {
    CollectionsRankingWeights = 'collections_ranking_weights',
    FrontendUrls = 'frontend_urls',
    SummaryHighlightsModel = 'summary_highlights_model',
    SummaryCommentsModel = 'summary_comments_model',
    SummarySlackOverviewModel = 'summary_slack_overview_model',
    PostTitleGenerationModel = 'post_title_generation_model',
    PollQueueWorkerConfig = 'poll_queue_worker_config',
    ConnectorRateLimits = 'connector_rate_limits',
    FollowThroughsOneLinerModel = 'followthroughs_one_liner_model',
}

export type DynamicConfigConnectorRateLimits = Record<
    ConnectorName,
    { maxConcurrent: number; minTime: number; timeoutMs?: number }
>;

export function makeStatsigUser(user: MetricsUser) {
    return {
        userID: user.userId,
        ip: user.ip,
        userAgent: user.userAgent,
        custom: { emailDomain: user.emailDomain, organizationId: user.organizationId },
    };
}
