// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import { GridAreaType } from 'grommet/utils';
import { useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { FontFamily } from '../../../../../theme';
import { FiltersOutput } from './FiltersOutput';
import { SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID, TemplateBlock, TemplateBlockState } from './TemplateBlock';

export function MagicComposerBuilder(props: {
    gridArea: GridAreaType;
    pageTitle: string;
    templateName: string;
    onUpdateTemplateName: (templateName: string) => void;
    blocks: TemplateBlockState[];
    onAddBlock: () => void;
    onUpdateBlock: (block: TemplateBlockState) => void;
    onDeleteBlock: (blockIndex: number) => void;
    onLoading: (loading: boolean) => void;
    sessionCacheId: string;
}): JSX.Element {
    const [activeBlockIndex, setActiveBlockIndex] = useState<number | undefined>(0);
    const [loadingTimeline, setLoadingTimeline] = useState<boolean>(false);
    const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
    return (
        <Box
            gridArea={props.gridArea}
            height="100%"
            style={{ background: Colors.background_front }}
            pad={{ horizontal: 'xsmall', top: '10px' }}
            gap="10px"
        >
            {/* Page title */}
            <Text size="28px" style={{ fontFamily: FontFamily.Callout }} color={Colors.dark_2} weight="bold">
                {props.pageTitle}
            </Text>
            <Box
                height="100%"
                background={Colors.background_back}
                round={{ corner: 'top', size: '15px' }}
                pad="xsmall"
                gap="xsmall"
                direction="column"
                border={{ color: Colors.border_light, size: '1px' }}
            >
                <Box direction="row" gap="xsmall" flex>
                    {/* Blocks listing */}
                    <Box fill={'vertical'} overflow={{ vertical: 'auto' }} flex>
                        <Box height={{ min: 'max-content', height: 'max-content' }} gap="xxsmall">
                            {props.blocks.map((block, bi) => (
                                <TemplateBlock
                                    key={`block-${bi}`}
                                    onUpdateState={(updatedBlock) => {
                                        const newState: TemplateBlockState = {
                                            ...updatedBlock,
                                            selectedInsightIds: block.selectedInsightIds.includes(
                                                SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID,
                                            )
                                                ? updatedBlock.selectedInsightIds
                                                : block.selectedInsightIds,
                                        };
                                        props.onUpdateBlock(newState);
                                    }}
                                    state={block}
                                    sessionCacheId={props.sessionCacheId}
                                    onDelete={() => props.onDeleteBlock(bi)}
                                    active={activeBlockIndex === bi}
                                    setActiveBlock={(active) =>
                                        active ? setActiveBlockIndex(bi) : setActiveBlockIndex(undefined)
                                    }
                                    onLoadingSummary={(l) => {
                                        setLoadingSummary(l);
                                        props.onLoading(l);
                                    }}
                                    onLoadingTimeline={setLoadingTimeline}
                                />
                            ))}

                            {/* Add block button */}
                            <Box
                                pad="xsmall"
                                background={{ color: Colors.background_front }}
                                round={{ size: '15px' }}
                                align="center"
                                border={{ color: Colors.border_light, size: '1px' }}
                            >
                                <IconButtonV2
                                    icon={(hover) => (
                                        <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />
                                    )}
                                    label={(hover) => (
                                        <Text
                                            size="14px"
                                            style={{ fontFamily: FontFamily.Standard }}
                                            color={hover ? Colors.accent_3 : Colors.dark_2}
                                        >
                                            Add new section
                                        </Text>
                                    )}
                                    reverse
                                    onClick={() => {
                                        props.onAddBlock();
                                        setActiveBlockIndex(props.blocks.length);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/* Filters Output */}
                    <FiltersOutput
                        key={`index-${activeBlockIndex}`}
                        activeBlock={activeBlockIndex != null ? props.blocks[activeBlockIndex] : undefined}
                        onUpdateGroupType={(group) => {
                            if (activeBlockIndex != null) {
                                const newState: TemplateBlockState = {
                                    ...props.blocks[activeBlockIndex],
                                };
                                newState.groupSettings.groupType = group;
                                props.onUpdateBlock(newState);
                            }
                        }}
                        onCustomSettingsChange={(customSettings) => {
                            if (activeBlockIndex != null) {
                                const newState: TemplateBlockState = {
                                    ...props.blocks[activeBlockIndex],
                                    summarizationCustomSettings: customSettings,
                                };
                                props.onUpdateBlock(newState);
                            }
                        }}
                        onIndicatorToggled={(indicator) => {
                            if (activeBlockIndex != null) {
                                const selectedInsights = props.blocks[activeBlockIndex].selectedInsightIds;
                                const set = new Set(selectedInsights);
                                set.delete(SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID);
                                if (set.has(indicator)) {
                                    set.delete(indicator);
                                } else {
                                    set.add(indicator);
                                }
                                const newState: TemplateBlockState = {
                                    ...props.blocks[activeBlockIndex],
                                    selectedInsightIds: Array.from(set),
                                };
                                props.onUpdateBlock(newState);
                            }
                        }}
                        timelineLoading={loadingTimeline}
                        summaryLoading={loadingSummary}
                    />
                </Box>
            </Box>
        </Box>
    );
}
