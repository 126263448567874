// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Anchor, Box, Spinner, Text } from 'grommet';
import { FormLock, Refresh } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { gql } from '../../../../../__generated__';
import { ConnectorConnectedState, SlackChannel, SlackTeamChannelsIds } from '../../../../../__generated__/graphql';
import { DropdownMenuComponent } from '../../../../../components/DropdownMenu';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { SlackTeamUserState, useUserStateContext } from '../../../../../components/UserContext';
import { useNavNoRerender } from '../../../../../shared/UseNavNoRerender';

export const GET_SLACK_CHANNELS = gql(/* GraphQL */ `
    query GetSlackChannels($connectorUserId: String!) {
        slackChannels(connectorUserId: $connectorUserId) {
            teamId
            teamName
            channels {
                id
                name
                private
            }
        }
    }
`);

function SlackChannelList(props: {
    onSelected: (teamId: string, teamName: string, channels: SlackChannel[]) => void;
    slackTeam: SlackTeamUserState;
    selectedIds: string[];
}): JSX.Element | null {
    const [selected, setSelected] = useState<
        {
            label: string;
            id: string;
        }[]
    >(() => {
        return props.slackTeam.channels
            .filter((channel) => props.selectedIds.includes(channel.id))
            .map((channel) => ({
                label: channel.name,
                id: channel.id,
            }));
    });

    return (
        <Box>
            <DropdownMenuComponent
                label={
                    selected.length === 0
                        ? `Team: ${props.slackTeam.teamName}`
                        : selected.length === 1
                        ? selected[0].label
                        : `${selected.length} channels selected`
                }
                options={props.slackTeam.channels.map((ch) => ({
                    id: ch.id,
                    label: ch.name,
                    icon: ch.private ? (
                        <FormLock size="18px" />
                    ) : (
                        <Text size="18px" style={{ marginRight: '2px' }}>
                            #
                        </Text>
                    ),
                }))}
                selected={selected}
                onSelectionChange={(sel) => {
                    setSelected(sel);

                    const channelIds = sel.map((s) => s.id);
                    props.onSelected(
                        props.slackTeam.teamId,
                        props.slackTeam.teamName,
                        props.slackTeam.channels.filter((channel) => channelIds.includes(channel.id)),
                    );
                }}
                getTextProps={() => ({ color: Colors.dark_3, size: '16px', style: { fontWeight: 'bold' } })}
                boxProps={{
                    pad: '5px 16px',
                    border: { color: Colors.accent_3 },
                    justify: 'between',
                    round: '8px',
                    margin: '0',
                }}
            />
        </Box>
    );
}

export function SlackChannels(props: {
    onSelectionChange: (slackNotificationState: SlackTeamChannelsIds[]) => void;
    existingNotifications: SlackTeamChannelsIds[];
}): JSX.Element | null {
    const { userState, refreshSlackTeam: refreshSlackWorkspace, slackTeam } = useUserStateContext();
    const [slackConnectors, setSlackConnectors] = useState<ConnectorConnectedState[]>([]);
    const navigate = useNavNoRerender();
    const [teamChannels, setTeamChannels] = useState<Map<string, SlackTeamChannelsIds>>(
        new Map(props.existingNotifications.map((n) => [n.teamId, n])),
    );

    useEffect(() => {
        if (!userState) {
            setSlackConnectors([]);
            return;
        }

        setSlackConnectors(userState.connectedConnectors.filter((c) => c.connector === ConnectorName.SLACK));
    }, [userState]);

    if (slackConnectors.length === 0 || !slackTeam) {
        return (
            <Text>
                <Anchor onClick={() => navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.connect.root]))}>
                    Connect
                </Anchor>{' '}
                Slack to receive notifications.
            </Text>
        );
    }

    return (
        <Box direction="row" gap="xsmall" align="center">
            <Text>Share to Slack</Text>
            <Box direction="row" gap="xxsmall" align="center">
                {slackConnectors.map((s) => (
                    <SlackChannelList
                        key={s.connectorUserId}
                        slackTeam={slackTeam}
                        onSelected={(teamId, _teamName, channels) => {
                            const newMap = new Map(teamChannels);
                            newMap.set(teamId, {
                                teamId,
                                channelIds: channels.map((c) => c.id),
                            });
                            setTeamChannels(newMap);
                            props.onSelectionChange(Array.from(newMap.values()));
                        }}
                        selectedIds={
                            props.existingNotifications.find((n) => n.teamId === s.connectorUserId)?.channelIds || []
                        }
                    />
                ))}
                <IconButtonV2
                    icon={(hover, _disabled, submitting) =>
                        submitting ? (
                            <Spinner width="14px" height="14px" />
                        ) : (
                            <Refresh size="16px" color={hover ? Colors.brand : undefined} />
                        )
                    }
                    onClickWithSpinner={async () => {
                        await refreshSlackWorkspace();
                    }}
                    tooltip="Refresh channel list"
                />
            </Box>
        </Box>
    );
}
