// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { getReadableDateString, getReadableDateTimeString } from '@thatworks/shared-frontend/date-helpers';
import { PmNodeNames, TaskPreviewBase } from '@thatworks/shared-frontend/prosemirror';
import { BasePmNodeAttributes } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Anchor, Box, Drop, Grid, Image, Text } from 'grommet';
import { ShareRounded } from 'grommet-icons';
import { SkeletonType } from 'grommet/utils';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NodeExtensionSpec } from 'remirror';
import { v4 } from 'uuid';
import {
    ActivityItemComments,
    ActivityItemPropertyType,
    ActivityItemPropertyValueType,
    ChangeDescription,
} from '../../../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../../../components/ConnectorIcon';
import { IconButtonV2 } from '../../../../../../components/IconButton';
import { BaseReactNode, BaseReactNodeComponentProps } from '../../../../../../components/prosemirror/BaseReactNode';
import { InspectorCardTag } from '../../../../../../components/SummaryComponents';
import { TwHeading } from '../../../../../../components/TwHeading';
import { DUE_SOON_DAYS_AHEAD } from '../../../magic-composer/helpers/preset-filters';

const MAX_DESCRIPTION_WORDS = 200;

function addOpacityToHex(hex: string, opacityPc: number): string {
    const opacity = opacityPc / 100;
    const _opacity = Math.round(opacity * 255);
    return hex + _opacity.toString(16).toUpperCase();
}
const SHOWABLE_PROPERTIES = [
    ActivityItemPropertyType.EndDate,
    ActivityItemPropertyType.Priority,
    ActivityItemPropertyType.Status,
    ActivityItemPropertyType.Assignee,
];

export type TaskPreviewInfo = TaskPreviewBase<
    ActivityItemPropertyValueType,
    ActivityItemPropertyType,
    ActivityItemComments,
    ChangeDescription
>;

export interface TaskPreviewAttributes<TaskPreviewInfoType> extends BasePmNodeAttributes {
    tasks: TaskPreviewInfoType[];
}

interface ColorWithOpacity {
    color: string;
    opacity?: number;
}

function getEndDateColor(endDateValue: string): ColorWithOpacity | undefined {
    const endDate = DateTime.fromISO(endDateValue);
    const now = DateTime.now();
    const dueSoon = now.plus({ days: DUE_SOON_DAYS_AHEAD });
    if (endDate.valueOf() < now.valueOf()) {
        return { color: '#e6ad9a' };
    }
    if (endDate.valueOf() < dueSoon.valueOf()) {
        return { color: '#f5d387' };
    }
    return undefined;
}

function getCardColor(props: TaskPreviewInfo['properties']): ColorWithOpacity | undefined {
    const endDateProp = props.find((p) => p.propertyType === ActivityItemPropertyType.EndDate);
    if (endDateProp && endDateProp.valueType === ActivityItemPropertyValueType.DateIso) {
        return getEndDateColor(endDateProp.value);
    }
    return undefined;
}

export function TaskOverview(props: {
    item: TaskPreviewInfo;
    cardColor?: ColorWithOpacity;
    borderColor?: string;
    selected?: boolean;
    onItemSelected?: (selected: boolean, id: string) => void;
}): JSX.Element {
    // Initialise
    const showSummary = !!(props.item.docDiff && props.item.docDiff.summary);
    const showComments = !!props.item.comments;

    // Get description
    const description = useMemo(() => {
        // Try to get the description from the properties. If not available get it from the deprecated field
        let description = props.item.properties.find(
            (prop) => prop.propertyType === ActivityItemPropertyType.Description,
        )?.value;

        // Parse description to only show n words
        return parseTaskDescription(description);
    }, [props.item.properties]);

    const getInspectorCardValue = useCallback(
        (p: TaskPreviewInfo['properties']['0']) => {
            // Initialize value
            let value: string = p.value;

            // Check if the value type is date to have the value formatted
            if (p.valueType === ActivityItemPropertyValueType.DateIso) {
                value = getReadableDateString(new Date(p.value));
            }

            // Check if the property type is status to try to get the previous type
            if (p.propertyType === ActivityItemPropertyType.Status) {
                const statusChange = props.item.changeDescription?.find(
                    (element) => element.length === 3 && element[1].value === '→',
                );
                if (statusChange) {
                    value = `${statusChange[0].value} ${statusChange[1].value} ${value}`;
                }
            }

            // Return the value
            return value;
        },
        [props.item.changeDescription],
    );

    //  Flag to show the item description
    const showDescription = !!(!showSummary && description);

    // Return
    return (
        <Box
            border={{
                color: props.selected ? Colors.accent_3 : props.borderColor || Colors.border_light,
                size: '1px',
            }}
            round="8px"
            background={{
                color: props.selected ? Colors.background_back : props.cardColor?.color || Colors.background_back,
                opacity: props.cardColor?.opacity || 1,
            }}
            focusIndicator={false}
            //onClick={() => {
            //    if (props.onItemSelected && props.selected !== undefined) {
            //        props.onItemSelected(!props.selected, props.item.id);
            //    }
            //}}
        >
            {/* Header */}
            <Box
                direction="row"
                pad={{ left: 'small', bottom: 'xxsmall', top: 'xxsmall', right: 'small' }}
                border={{ side: 'bottom', color: Colors.border_light }}
                height={{ min: '15px' }}
                justify="between"
                gap="xxsmall"
                align="center"
            >
                <Box direction="row" gap="xxsmall" align="center">
                    <Box>
                        <ConnectorIconSmall
                            name={props.item.connector as ConnectorName}
                            sizePixels="10px"
                            alignSelf="center"
                        />
                    </Box>
                    <Text
                        size="10px"
                        weight="bolder"
                        color={Colors.brand}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        {props.item.parents.length === 0 ? `${getFormattedConnectorName(props.item.connector)}` : ''}
                        {props.item.parents.slice(0, 2).map((p, i, arr) => (
                            <span key={`card-parent-${i}`}>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {p.connectorObjectType}&nbsp;•&nbsp;
                                </span>
                                <Anchor
                                    href={p.url || undefined}
                                    target="_blank"
                                    color={Colors.brand}
                                    style={{ borderWidth: '0px' }}
                                >
                                    {p.name}
                                </Anchor>
                                <span>{i < arr.length - 1 ? ' |' : ''}&nbsp;</span>
                            </span>
                        ))}
                    </Text>
                </Box>
                {props.item.url && (
                    <Box pad="2px">
                        <IconButtonV2
                            icon={(hover) => <ShareRounded size="12px" color={hover ? Colors.brand : Colors.dark_4} />}
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                    window.open(props.item.url, '_blank');
                                }
                            }}
                        />
                    </Box>
                )}
            </Box>
            {/* Body */}
            <Box
                direction="row"
                focusIndicator={false}
                pad={{ left: 'small', right: 'xsmall', top: 'xsmall', bottom: 'small' }}
                width="100%"
            >
                <Box direction="row" gap="xxsmall" width="100%">
                    <Box gap="xxsmall" justify="center" width="100%">
                        {/* Title */}
                        <Box direction="row" justify="between" align="start" width="100%">
                            <Box direction="row" align="top" wrap gap="xxsmall">
                                <TwHeading level={6} maxWidth={'1000px'}>
                                    {props.item.iconUrl && (
                                        <span style={{ minWidth: 'max-content', marginRight: '2px' }}>
                                            <Image
                                                src={props.item.iconUrl}
                                                width={'12px'}
                                                height={'12px'}
                                                alt="Icon"
                                                fallback={process.env.REACT_APP_ITEM_ICON_FALLBACK_URL}
                                            />
                                        </span>
                                    )}
                                    {props.item.title}
                                </TwHeading>
                            </Box>
                        </Box>
                        {/* Content */}
                        <Box gap="4px">
                            {props.item.properties && props.item.properties.length > 0 && (
                                <Box direction="row" gap="4px" wrap>
                                    {props.item.properties.flatMap((p, pIdx) => {
                                        // Remove property that should not be shown in the inspector card tag
                                        if (!SHOWABLE_PROPERTIES.includes(p.propertyType)) {
                                            return [];
                                        }

                                        // Return
                                        return [
                                            <InspectorCardTag
                                                color={
                                                    p.propertyType === ActivityItemPropertyType.EndDate
                                                        ? getEndDateColor(p.value)?.color || undefined
                                                        : p.color
                                                        ? addOpacityToHex(p.color, 25)
                                                        : undefined
                                                }
                                                key={`${props.item.id}-prop-${pIdx}`}
                                                name={p.name}
                                                value={getInspectorCardValue(p)}
                                                dontClipText
                                                iconUrl={p.iconUrl}
                                                margin={{ bottom: '4px' }}
                                            />,
                                        ];
                                    })}
                                </Box>
                            )}
                        </Box>
                        {/* Summary */}
                        {showSummary && (
                            <Box gap="xxsmall">
                                <Text
                                    size={'12px'}
                                    weight="bold"
                                    style={{
                                        color: Colors.dark_4,
                                        display: 'inline-block',
                                    }}
                                >
                                    Summary of changes
                                </Text>
                                <Text
                                    size={'12px'}
                                    style={{
                                        color: Colors.dark_4,
                                        display: 'inline-block',
                                    }}
                                >
                                    {props.item.docDiff?.summary}
                                </Text>
                            </Box>
                        )}
                        {/* Description */}
                        {showDescription && (
                            <Box gap="xxsmall">
                                <Text
                                    size={'12px'}
                                    weight="bold"
                                    style={{
                                        color: Colors.dark_4,
                                        display: 'inline-block',
                                    }}
                                >
                                    Description
                                </Text>
                                <Text
                                    size={'12px'}
                                    style={{
                                        color: Colors.dark_4,
                                        display: 'inline-block',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {description}
                                </Text>
                            </Box>
                        )}
                        {/* Comments */}
                        {showComments && props.item.comments && props.item.comments.comments.length > 0 && (
                            <ItemComments comments={props.item.comments} />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function parseTaskDescription(rawDescription: string | undefined): string | undefined {
    // Check if we have description
    if (!rawDescription) {
        return rawDescription;
    }

    // Split the raw description with a positive lookahead assertion (Does not remove the spaces)
    const descriptionArray = rawDescription.split(/(?=\s)/);

    // Slice the Array based on MAX_DESCRIPTION_WORDS
    let description = descriptionArray.slice(0, MAX_DESCRIPTION_WORDS).join('');

    // If the descriptionArray length is higher than the MAX_DESCRIPTION_WORDS we need to add dots
    if (descriptionArray.length > MAX_DESCRIPTION_WORDS) {
        description = description + '...';
    }

    // Return
    return description;
}

export function TaskCard(props: { item: TaskPreviewInfo; selected?: boolean; skeleton?: SkeletonType }): JSX.Element {
    const [modal, setModal] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const [cardColor, setCardColor] = useState<ColorWithOpacity | undefined>(undefined);

    useEffect(() => {
        const color = getCardColor(props.item.properties);
        setCardColor(color);
    }, [props.item.properties]);

    return (
        <Box
            border={
                props.selected
                    ? undefined
                    : { color: props.selected ? 'brand' : cardColor?.color || 'border', size: '2px' }
            }
            round={props.skeleton ? undefined : '12px'}
            background={{
                color: props.skeleton ? undefined : 'background-back',
            }}
            focusIndicator={false}
            id={props.item.id}
            height="max-content"
            width={{ min: 'unset' }}
            onMouseEnter={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
        >
            <Box
                direction="row"
                align="center"
                focusIndicator={false}
                pad={{ left: 'xsmall', right: 'xsmall', top: 'xxsmall', bottom: 'xxsmall' }}
                skeleton={props.skeleton}
                ref={ref}
            >
                <Box direction="row" align="center" gap="xxsmall">
                    {!props.item.iconUrl && (
                        <ConnectorIconSmall
                            name={props.item.connector as ConnectorName}
                            sizePixels="12px"
                            alignSelf="center"
                        />
                    )}
                    {props.item.iconUrl && (
                        <Image
                            src={props.item.iconUrl}
                            width={'12px'}
                            height={'12px'}
                            alt="Icon"
                            fallback={process.env.REACT_APP_ITEM_ICON_FALLBACK_URL}
                        />
                    )}
                    <Text
                        weight="bold"
                        size="12px"
                        color={Colors.brand}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {props.item.title}
                    </Text>
                </Box>
            </Box>
            {modal && (
                <Drop
                    target={ref}
                    onClickOutside={() => setModal(false)}
                    plain
                    align={{ top: 'bottom', left: 'left' }}
                    pad={{ top: 'xxsmall', horizontal: 'xsmall', bottom: 'xsmall' }}
                    width={{ max: '500px' }}
                    animation={{ type: 'fadeIn', duration: 100 }}
                >
                    <TaskOverview item={props.item} cardColor={cardColor} />
                </Drop>
            )}
        </Box>
    );
}

export class TaskPreviewNode extends BaseReactNode<TaskPreviewAttributes<TaskPreviewInfo>> {
    static get nodeName() {
        return PmNodeNames.TaskPreview;
    }

    get name(): string {
        return PmNodeNames.TaskPreview as const;
    }

    getDefaultAttributes(): TaskPreviewAttributes<TaskPreviewInfo> {
        return {
            uuid: v4(),
            tasks: [],
        };
    }

    get content(): string | undefined {
        return undefined;
    }

    nodeSpecOverride(): NodeExtensionSpec | undefined {
        return {
            atom: true,
        };
    }

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<TaskPreviewAttributes<TaskPreviewInfo>>> = (
        props,
    ) => {
        return (
            <Grid columns="1/3" gap={{ row: 'xxsmall', column: 'xxsmall' }} justifyContent="center">
                {props.currentAttributes.tasks.map((task) => (
                    <TaskCard item={task} key={`itemp-${task.id}`} />
                ))}
            </Grid>
        );
    };
}

export function ItemComments(props: { comments: TaskPreviewInfo['comments'] }): JSX.Element {
    // Build comments list
    const comments = useMemo(() => {
        return props.comments?.comments.map((comment, commentIndex) => (
            <Box key={`comment-${commentIndex}`}>
                <Box>
                    <Text size="12px" color={Colors.dark_4}>
                        {comment.authorDisplayName} at {getReadableDateTimeString(new Date(comment.date))}
                    </Text>
                </Box>
                <Box>
                    <Text size="12px" color={Colors.dark_4}>
                        {comment.comment}
                    </Text>
                </Box>
            </Box>
        ));
    }, [props.comments]);

    // Return
    return (
        <Box gap="xxsmall">
            <Box>
                <Text size="12px" weight="bold" color={Colors.dark_4}>
                    Recent Discussions
                </Text>
            </Box>
            <Box gap="xxsmall">{comments}</Box>
        </Box>
    );
}
