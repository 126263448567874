// Copyright 2021
// ThatWorks.xyz Limited

import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from '@apollo/client';
import {
    ActivityItemFilterOperator,
    ActivityItemFilterOutput,
    AutomationInput,
    AutomationOutput,
    AutomationScheduleEvery,
    AutomationUpdateInput,
    CreateTemplateMutation,
    Exact,
    GetWorkspacePostTemplatesQuery,
    SlackNotificationCreate,
    SlackTeamChannelsIds,
    WorkspacePostTemplateCreateInput,
    WorkspacePostTemplateCreateQueryBlock,
    WorkspacePostTemplateQuery,
    WorkspacePostTemplateUpdateInput,
    WorkspacePostTemplateUpdateMutation,
} from '../../../../../__generated__/graphql';
import { AutomationState } from '../components/MagicComposerAutomate';
import { getDefaultTemplateBlockProps, TemplateBlockState } from '../components/TemplateBlock';
import { getActivityFilters, getPropertyFilters } from '../filters/activity-property-helpers';
import { DEFAULT_GROUP_SETTINGS } from '../filters/GroupToolbarButton';
import {
    timelineCreateDateToTimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../filters/timeline-date-selection';
import { getActivityQueryVars, getPresetFromString, PresetFilter } from './preset-filters';

const DEFAULT_BLOCKS_STATE: TemplateBlockState[] = [getDefaultTemplateBlockProps()];
const DEFAULT_AUTOMATION: AutomationState = {
    every: AutomationScheduleEvery.WeekDay,
    time: { hour: 9, minute: 0, ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    isoDayOfWeek: 1,
    workspace: undefined,
};

export interface TemplateQl {
    createTemplateQl: CreateTemplateQl;
    updateTemplateQl: UpdateTemplateQl;
}

interface CreateTemplateQl {
    (
        options?:
            | MutationFunctionOptions<
                  CreateTemplateMutation,
                  Exact<{ template: WorkspacePostTemplateCreateInput }>,
                  DefaultContext,
                  ApolloCache<any>
              >
            | undefined,
    ): Promise<FetchResult<CreateTemplateMutation>>;
    (arg0: {
        variables: {
            template: WorkspacePostTemplateCreateInput;
        };
    }): FetchResult<CreateTemplateMutation> | PromiseLike<FetchResult<CreateTemplateMutation>>;
}

interface UpdateTemplateQl {
    (
        options?:
            | MutationFunctionOptions<
                  WorkspacePostTemplateUpdateMutation,
                  Exact<{ id: string; template: WorkspacePostTemplateUpdateInput }>,
                  DefaultContext,
                  ApolloCache<any>
              >
            | undefined,
    ): Promise<FetchResult<WorkspacePostTemplateUpdateMutation>>;
    (arg0: {
        variables: {
            id: string;
            template: WorkspacePostTemplateUpdateInput;
        };
    }):
        | FetchResult<WorkspacePostTemplateUpdateMutation>
        | PromiseLike<FetchResult<WorkspacePostTemplateUpdateMutation>>;
}

export function getTemplateBlockStatesFromTemplatesQuery(
    queryBlocks: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0]['queryBlocks'],
): TemplateBlockState[] {
    // Initialize blocks
    const blocks: TemplateBlockState[] = [];

    // Iterate over each query block received
    queryBlocks.forEach((block) => {
        // Get the template default block state
        const templateBlockState = getDefaultTemplateBlockProps();

        // Update the block's title
        templateBlockState.title = block.filterName ?? '';

        // Update the block's date selection
        templateBlockState.dateSelection = timelineCreateDateToTimelineDateSelection(block.date);

        // Update the block's property filter groups
        templateBlockState.propertyFilterGroups = getPropertyFilters(
            block.filters.filters as ActivityItemFilterOutput[],
        );

        // Update the block's filters operator
        templateBlockState.filtersOperator = block.filters.operator;

        // Update the block's group settings
        templateBlockState.groupSettings = {
            groupType: block.grouping.groupType,
        };

        // Update the block's selected scopes
        templateBlockState.selectedScopes = block.scopes.map((scope) => ({
            connector: scope.connector,
            hierarchyType: scope.hierarchyType,
            id: `${scope.hierarchyType}${scope.itemUuid}`,
            itemName: scope.itemName,
            itemUuid: scope.itemUuid,
        }));

        // Update the preset
        templateBlockState.preset = getPresetFromString(block.preset);

        // Update the summarization style
        templateBlockState.summarizationCustomSettings = {
            levelOfDetail: block.summarizationSettings.levelOfDetail,
            showCommentsSummary: block.summarizationSettings.showCommentsSummary,
            summaryFormat: block.summarizationSettings.summaryFormat,
            alsoGroupByItemType: block.summarizationSettings.alsoGroupByItemType,
        };

        // Update the selected indicators
        templateBlockState.selectedInsightIds = block.selectedIndicatorIds;

        // Add the block to the array of blocks
        blocks.push(templateBlockState);
    });

    // Return the blocks
    return blocks;
}

export function getBlocks(edit: WorkspacePostTemplateQuery['workspacePostTemplate'] | undefined): TemplateBlockState[] {
    // If we have received the edit, parse the blocks and return them
    if (edit) {
        return getTemplateBlockStatesFromTemplatesQuery(edit.queryBlocks);
    }

    // Return the default blocks
    return DEFAULT_BLOCKS_STATE;
}

export function getAutomation(edit: WorkspacePostTemplateQuery['workspacePostTemplate'] | undefined): AutomationState {
    // If we have received the automation, parse the automation and return it
    if (edit?.automation) {
        const schedule = edit.automation.schedule;
        const workspace = edit.automation.destinations;
        return {
            every: schedule.every,
            time: { hour: schedule.hour, minute: schedule.minute, ianaTimeZone: schedule.ianaTimeZone },
            isoDayOfWeek: schedule.isoDayOfWeek ?? 1,
            workspace: { id: workspace.workspaceId, label: workspace.workspaceName },
        };
    }

    // Return the default automation
    return DEFAULT_AUTOMATION;
}

export async function createOrEditTemplate(
    templateName: string,
    validQueryBlocks: WorkspacePostTemplateCreateQueryBlock[],
    slackTeamsChannels: SlackTeamChannelsIds[],
    templateData: WorkspacePostTemplateQuery['workspacePostTemplate'] | undefined,
    templateQl: TemplateQl,
    edit: boolean,
    automationState?: AutomationState,
): Promise<FetchResult<CreateTemplateMutation> | FetchResult<WorkspacePostTemplateUpdateMutation> | undefined> {
    // Get automation
    let automation: AutomationInput | AutomationUpdateInput | undefined;
    if (automationState?.workspace) {
        automation = {
            enabled: true,
            destinations: { workspaceId: automationState.workspace.id },
            schedule: {
                every: automationState.every,
                hour: automationState.time.hour,
                minute: automationState.time.minute,
                ianaTimeZone: automationState.time.ianaTimeZone,
                isoDayOfWeek:
                    automationState.every === AutomationScheduleEvery.Week ? automationState.isoDayOfWeek : undefined,
            },
        };
    }

    let slackNotifications: SlackNotificationCreate[] = slackTeamsChannels.map((slackTeamChannels) => ({
        teamId: slackTeamChannels.teamId,
        channelIds: slackTeamChannels.channelIds,
    }));

    // Check if we don't have edit present. If so it means that we are in creation mode and we need to create a template
    if (!edit || templateData == null) {
        // Create template and return
        return await createTemplate(
            templateQl.createTemplateQl,
            templateName,
            validQueryBlocks,
            slackNotifications,
            automation as AutomationInput,
        );
    }

    // Since we have edit present it means that we are currently editing a template
    else {
        if (automation?.enabled === false) {
            // Just udpate the enable flag even if the user has made changes to other automation fields
            automation = {
                enabled: false,
            };
            if (!templateData.automation) {
                automation = undefined;
            }
        } else if (!hasAutomationChanges(templateData.automation, automationState)) {
            automation = undefined;
        }

        return await updateTemplate(
            templateQl.updateTemplateQl,
            templateData.id,
            templateName,
            validQueryBlocks,
            slackNotifications,
            automation as AutomationUpdateInput,
        );
    }
}

function hasAutomationChanges(
    previousAutomation: AutomationOutput | undefined | null,
    automation: AutomationState | undefined,
): boolean {
    if (
        previousAutomation?.destinations.workspaceId !== automation?.workspace?.id ||
        automation?.every !== previousAutomation?.schedule.every ||
        automation?.time.hour !== previousAutomation?.schedule.hour ||
        automation?.time.minute !== previousAutomation?.schedule.minute
    ) {
        return true;
    }
    return false;
}

export async function createTemplate(
    createTemplateQl: CreateTemplateQl,
    templateName: string,
    validQueryBlocks: WorkspacePostTemplateCreateQueryBlock[],
    slackNotifications: SlackNotificationCreate[],
    automation: AutomationInput | undefined,
): Promise<FetchResult<CreateTemplateMutation>> {
    return await createTemplateQl({
        variables: {
            template: {
                title: templateName,
                queries: {
                    blocks: validQueryBlocks,
                },
                automation,
                slackNotifications: slackNotifications,
            },
        },
    });
}

export async function updateTemplate(
    updateTemplateQl: UpdateTemplateQl,
    templateId: string,
    templateName: string,
    validQueryBlocks: WorkspacePostTemplateCreateQueryBlock[],
    slackNotifications: SlackNotificationCreate[],
    automation: AutomationUpdateInput | undefined,
): Promise<FetchResult<WorkspacePostTemplateUpdateMutation>> {
    return await updateTemplateQl({
        variables: {
            id: templateId,
            template: {
                title: templateName,
                queries: {
                    blocks: validQueryBlocks,
                },
                automation,
                slackNotifications: slackNotifications,
            },
        },
    });
}

export function getValidQueryBlocks(blocks: TemplateBlockState[]) {
    const queryBlocks: WorkspacePostTemplateCreateQueryBlock[] = [];
    blocks.forEach((b) => {
        if (!b.dateSelection || b.selectedScopes.length === 0 || (!b.preset && b.propertyFilterGroups.length === 0)) {
            return;
        }

        const f = getActivityQueryVars(
            b.groupSettings,
            getActivityFilters(b.propertyFilterGroups, b.filtersOperator, b.dateSelection),
        );

        const bb: WorkspacePostTemplateCreateQueryBlock = {
            filterName: b.title,
            filters: f.filters || { filters: [], operator: ActivityItemFilterOperator.Or },
            grouping: f.grouping || DEFAULT_GROUP_SETTINGS,
            scopes: b.selectedScopes.map((item) => ({
                connector: item.connector,
                hierarchyType: item.hierarchyType,
                id: item.id,
                itemName: item.itemName,
                itemUuid: item.itemUuid,
            })),
            date: timelineDateSelectionToTimelineCreateDate(b.dateSelection),
            showIndicators: b.preset === PresetFilter.Indicators,
            showMetricCharts: b.preset === PresetFilter.Charts,
            preset: b.preset,
            summarizationSettings: b.summarizationCustomSettings,
            selectedIndicatorIds: b.selectedInsightIds,
        };
        queryBlocks.push(bb);
    });
    return queryBlocks;
}
