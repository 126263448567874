// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, CheckBox, Spinner, Text } from 'grommet';
import { Close, FormClose, Info, Search } from 'grommet-icons';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../../__generated__';
import { ConnectorScope, ConnectorScopeInput } from '../../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../../components/ConnectorIcon';
import { IconButtonV2, RoundIconButton } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { InspectorCardTag } from '../../../../../components/SummaryComponents';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';

const GET_DATA_SOURCE_FROM_URL = gql(/* GraphQL */ `
    mutation DataSourceCreateFromUrl($url: String!) {
        dataSourceCreateFromUrl(url: $url) {
            title
            itemId
            hierarchyType
            connector
            id
            connectorObjectType
            parentTitle
        }
    }
`);

export const FILTER_DATA_SOURCES_INPUT_ID = 'filter_data_sources_input';

const ITEM_SEARCH = gql(/* GraphQL */ `
    query ConnectorsScopeSearch($query: String!) {
        connectorsScopeSearch(query: $query) {
            id
            connector
            itemName
            itemType
            itemUuid
            hierarchyType
            parentName
        }
    }
`);

export function SearchItems(props: {
    onSelected: (selected: Map<string, ConnectorScopeInput>) => void;
    selected: Map<string, ConnectorScopeInput>;
    onClose: () => void;
}): JSX.Element {
    const [searchText, setSearchText] = useState('');
    const { logger } = useTelemetryContext();
    const { postErrorMessage, connectedConnectorsWithoutStatus } = useUserStateContext();
    const [searchResults, setSearchResults] = useState<ConnectorScope[]>([]);
    const [searchResultEmpty, setSearchResultEmpty] = useState(false);
    const [parsingUrl, setParsingUrl] = useState(false);

    const [getDataSourceFromUrl] = useMutation(GET_DATA_SOURCE_FROM_URL, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get data from URL' });
            logger.error(error.message);
        },
    });

    const [itemSearch, { loading }] = useLazyQuery(ITEM_SEARCH);

    const debouncedResults = useMemo(() => {
        return debounce((query: string) => {
            itemSearch({ variables: { query } }).then((r) => {
                setSearchResults(r.data ? r.data.connectorsScopeSearch : []);
                setSearchResultEmpty(!r.data || r.data.connectorsScopeSearch.length === 0);
            });
        }, 300);
    }, [itemSearch]);

    useEffect(() => {
        return () => debouncedResults.cancel();
    }, [debouncedResults]);

    const showSearchWrapper = useCallback((toggle: boolean) => {
        if (!toggle) {
            setSearchResults([]);
            setSearchText('');
        }
    }, []);

    const exampleSearchQueries = useMemo(() => {
        const queries = connectedConnectorsWithoutStatus.filter((c) => c.exampleDataSearchQueries.length > 0);
        let res: string[] = [];
        if (queries.length > 2) {
            res = queries.flatMap((c) => c.exampleDataSearchQueries.slice(0, 1)).slice(0, 4);
        } else {
            res = queries.flatMap((c) => c.exampleDataSearchQueries);
        }

        return Array.from(new Set(res));
    }, [connectedConnectorsWithoutStatus]);

    return (
        <Box>
            <Box height={{ min: 'unset' }}>
                <Box direction="row" gap="xsmall" pad={{ bottom: '3px' }}>
                    <Box
                        direction="row"
                        gap="10px"
                        align="center"
                        flex
                        border={{ side: 'bottom', color: Colors.brand }}
                    >
                        {!loading && !parsingUrl && <Search size="14px" color={Colors.brand} />}
                        {(loading || parsingUrl) && <Spinner width="14px" height={'14px'} size="1px" />}
                        <PlainTextInput
                            id={FILTER_DATA_SOURCES_INPUT_ID}
                            value={searchText}
                            autoFocus
                            autoComplete="off"
                            placeholder={
                                loading || parsingUrl
                                    ? 'Loading..'
                                    : 'Search for projects, boards, documents, tasks to track'
                            }
                            fontSize="14px"
                            style={{
                                fontFamily: FontFamily.Mono,
                                background: 'unset',
                                width: '100%',
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    e.preventDefault();
                                    showSearchWrapper(false);
                                }
                            }}
                            onChange={async (e) => {
                                let value = e.target.value;
                                setSearchText(value);

                                function isValidHttpUrl(v: string) {
                                    let url;
                                    try {
                                        url = new URL(v);
                                    } catch (_) {
                                        return false;
                                    }

                                    return url.protocol === 'http:' || url.protocol === 'https:';
                                }

                                if (isValidHttpUrl(value)) {
                                    setParsingUrl(true);
                                    const ds = await getDataSourceFromUrl({ variables: { url: value } });
                                    if (
                                        ds.data &&
                                        ds.data.dataSourceCreateFromUrl &&
                                        ds.data.dataSourceCreateFromUrl.length > 0
                                    ) {
                                        const newScopes = ds.data.dataSourceCreateFromUrl.map((d) => {
                                            const newScope: ConnectorScope = {
                                                connector: d.connector,
                                                hierarchyType: d.hierarchyType,
                                                id: `${d.hierarchyType}-${d.itemId}`,
                                                itemName: d.title,
                                                itemType: d.connectorObjectType,
                                                itemUuid: d.itemId,
                                                parentName: d.parentTitle,
                                            };
                                            return newScope;
                                        });
                                        if (newScopes.length === 1) {
                                            props.selected.set(newScopes[0].id, newScopes[0]);
                                        }
                                        setSearchResults(newScopes.length > 0 ? [newScopes[0]] : []);
                                        value = newScopes[0].itemName;
                                    } else if (!ds.errors && !ds.data?.dataSourceCreateFromUrl) {
                                        postErrorMessage({ title: 'Error', shortDesc: 'Failed to parse URL' });
                                        logger.error(`Failed to parse URL: ${value}`);
                                    }
                                    setSearchText(value);
                                    setParsingUrl(false);
                                } else {
                                    setSearchText(value);
                                    debouncedResults(value);
                                }
                            }}
                        />
                    </Box>
                    <IconButtonV2
                        icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                        reverse
                        onClick={props.onClose}
                        alignSelf="end"
                    />
                </Box>
                {searchResults.length > 0 && (
                    <Box
                        height="145px"
                        overflow={{ vertical: 'auto' }}
                        pad="xxsmall"
                        margin={{ bottom: 'xxsmall', left: 'xsmall', top: 'xxsmall' }}
                        border={{ color: Colors.light_3, size: '1px', side: 'bottom' }}
                    >
                        <Box height={{ min: 'max-content' }}>
                            <Box gap="xxsmall">
                                {searchResults.map((r) => (
                                    <CheckBox
                                        key={`check-${r.id}`}
                                        checked={props.selected.has(r.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                props.selected.set(r.id, r);
                                            } else {
                                                props.selected.delete(r.id);
                                            }
                                            props.onSelected(props.selected);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Escape') {
                                                e.preventDefault();
                                                showSearchWrapper(false);
                                            }
                                        }}
                                        label={
                                            <Box gap="4px" direction="row" align="center">
                                                <ConnectorIconSmall
                                                    sizePixels="14px"
                                                    name={r.connector as ConnectorName}
                                                />
                                                {r.parentName && (
                                                    <InspectorCardTag
                                                        hideBorder
                                                        color="accent-3"
                                                        name=""
                                                        value={r.parentName}
                                                        hideTooltip
                                                    />
                                                )}
                                                {r.itemType && (
                                                    <InspectorCardTag
                                                        dontClipText
                                                        hideBorder
                                                        color="accent-3"
                                                        name=""
                                                        value={r.itemType}
                                                        hideTooltip
                                                    />
                                                )}

                                                <Text
                                                    size="14px"
                                                    style={{
                                                        fontFamily: FontFamily.Mono,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '330px',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {r.itemName}
                                                </Text>
                                            </Box>
                                        }
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box overflow={{ vertical: 'auto' }}>
                <Box
                    direction="row"
                    margin={{ top: 'xxsmall' }}
                    wrap
                    gap="xxsmall"
                    height={{ min: 'max-content', height: 'max-content' }}
                >
                    {props.selected.size === 0 && (
                        <Box>
                            {searchResultEmpty && searchText && (
                                <Text size="14px" style={{ fontFamily: FontFamily.Mono }} color={Colors.dark_4}>
                                    No results found
                                </Text>
                            )}
                            {(searchResultEmpty || !searchText) && connectedConnectorsWithoutStatus.length > 0 && (
                                <Box
                                    pad="xsmall"
                                    border={{ color: Colors.border_dark, size: '2px' }}
                                    round="10px"
                                    gap="xxsmall"
                                >
                                    <Box direction="row" align="center" gap="xxsmall">
                                        <Info size="12px" color={Colors.brand} />
                                        <Text size="small" weight="bold">
                                            Search Tips
                                        </Text>
                                    </Box>
                                    <Text size="small">
                                        <ol>
                                            <li>
                                                Selecting an item will include all data in its hierarchy. For example,
                                                selecing a project will include all tasks in the project.
                                            </li>
                                            <li>You can add multiple data sources from different apps</li>
                                        </ol>
                                    </Text>
                                    <Text size="small">Here are some examples. Click on them to run a search:</Text>
                                    <Box direction="row" align="center" wrap gap="4px">
                                        {exampleSearchQueries.map((h, hi) => (
                                            <Box
                                                key={`search-helper-${hi}`}
                                                pad={{ horizontal: '6px', vertical: '2px' }}
                                                border={{ color: Colors.black }}
                                                round="10px"
                                                margin={{ bottom: '4px' }}
                                                onClick={() => {
                                                    setSearchText(h);
                                                    debouncedResults(h);
                                                }}
                                            >
                                                <Text size="12px" style={{ fontFamily: FontFamily.Mono }}>
                                                    {h}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                    {Array.from(props.selected.values()).map((v, vi) => (
                        <Box
                            direction="row"
                            key={`selected-${vi}`}
                            gap="xxsmall"
                            background={Colors.background_back}
                            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                            round="5px"
                            align="center"
                            margin={{ bottom: 'xxsmall' }}
                        >
                            <ConnectorIconSmall name={v.connector as ConnectorName} sizePixels="14px" />
                            <Box direction="row" gap="xsmall">
                                <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                                    {v.itemName}
                                </Text>
                            </Box>
                            <RoundIconButton
                                icon={FormClose}
                                color={{ hover: Colors.brand, color: Colors.brand }}
                                sizePixels={12}
                                onClick={() => {
                                    props.selected.delete(v.id);
                                    props.onSelected(props.selected);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
