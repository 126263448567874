// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, CheckBox, Drop, Grid, RadioButtonGroup, RangeInput, Text } from 'grommet';
import { Close } from 'grommet-icons';
import isEqual from 'lodash.isequal';
import { useMemo, useRef, useState } from 'react';
import {
    SummarizationSettingsFormat,
    SummarizationSettingsInput,
    SummarizationStyle,
} from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2, CtaTextButtonSpinner } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { Palette } from '../../../../../icons/Palette';
import { FontFamily } from '../../../../../theme';

const SummarizationStyleArray = Object.values(SummarizationStyle);
const SummarizationSettingsFormatArray = [
    SummarizationSettingsFormat.Highlights,
    SummarizationSettingsFormat.DetailedList,
];

function getLabelForSummarizationStyle(style: SummarizationStyle): string {
    switch (style) {
        case SummarizationStyle.Highlights:
            return 'Highlights';
        case SummarizationStyle.ReleaseNotes:
            return 'Release Notes';
    }
}

function getLabelForSummarizationSettingsFormat(format: SummarizationSettingsFormat): string {
    switch (format) {
        case SummarizationSettingsFormat.DetailedList:
            return 'Detailed List';
        case SummarizationSettingsFormat.Highlights:
            return 'Short';
    }
}

export function getSummarySettingsForSummaryStyle(style: SummarizationStyle): SummarizationSettingsInput {
    switch (style) {
        case SummarizationStyle.Highlights:
            return {
                alsoGroupByItemType: true,
                showCommentsSummary: true,
                summaryFormat: SummarizationSettingsFormat.Highlights,
                levelOfDetail: 0,
            };
        case SummarizationStyle.ReleaseNotes:
            return {
                alsoGroupByItemType: false,
                showCommentsSummary: false,
                summaryFormat: SummarizationSettingsFormat.DetailedList,
                levelOfDetail: 1,
            };
    }
}

const LEVEL_OF_DETAIL_RANGE_INPUT_MAX_STEPS = 10;

function areSettingsEqual(a: SummarizationSettingsInput, b: SummarizationSettingsInput): boolean {
    return (
        a.alsoGroupByItemType === b.alsoGroupByItemType &&
        a.levelOfDetail === b.levelOfDetail &&
        a.showCommentsSummary === b.showCommentsSummary &&
        a.summaryFormat === b.summaryFormat
    );
}

export function CustomSummarizationSettingsView(props: {
    enabled: boolean;
    onEnabledChange: (enabled: boolean) => void;
    customSettings: SummarizationSettingsInput;
    onCustomSettingsChange: (customSettings: SummarizationSettingsInput) => void;
    dataLoading: boolean;
}): JSX.Element {
    const [customSettings, setCustomSettings] = useState<SummarizationSettingsInput>(props.customSettings);
    const settingsAreEqual = useMemo(() => {
        return areSettingsEqual(customSettings, props.customSettings);
    }, [customSettings, props.customSettings]);

    return (
        <Box gap="xsmall" border={{ color: Colors.light_5 }} round="8px" pad="xsmall">
            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                Customize
            </Text>

            <Box gap="xsmall">
                <Box gap="xxsmall">
                    <Text size="14px" weight={'bolder'} style={{ opacity: props.enabled ? undefined : 0.5 }}>
                        Information Detail
                    </Text>
                    <Box direction="row" align="center">
                        <Text size="14px" style={{ opacity: props.enabled ? undefined : 0.5 }}>
                            Highlights&nbsp;
                        </Text>
                        <RangeInput
                            disabled={props.dataLoading || !props.enabled}
                            min={0}
                            max={LEVEL_OF_DETAIL_RANGE_INPUT_MAX_STEPS}
                            step={1}
                            value={customSettings.levelOfDetail * LEVEL_OF_DETAIL_RANGE_INPUT_MAX_STEPS}
                            onChange={(e) => {
                                setCustomSettings((prev) => {
                                    return {
                                        ...prev,
                                        levelOfDetail: Number(e.target.value) / LEVEL_OF_DETAIL_RANGE_INPUT_MAX_STEPS,
                                    };
                                });
                            }}
                        />
                        <Text size="14px" style={{ opacity: props.enabled ? undefined : 0.5 }}>
                            &nbsp;Everything
                        </Text>
                    </Box>
                </Box>
                <Box gap="xxsmall">
                    <Text size="14px" weight={'bolder'} style={{ opacity: props.enabled ? undefined : 0.5 }}>
                        Summary Length
                    </Text>
                    <RadioButtonGroup
                        disabled={props.dataLoading || !props.enabled}
                        name="Display"
                        options={SummarizationSettingsFormatArray.map((o) => ({
                            value: o,
                            label: getLabelForSummarizationSettingsFormat(o),
                        }))}
                        direction="row"
                        value={customSettings.summaryFormat}
                        onChange={(event) => {
                            setCustomSettings((prev) => {
                                return {
                                    ...prev,
                                    summaryFormat: event.target.value as SummarizationSettingsFormat,
                                };
                            });
                        }}
                    />
                </Box>
                <Box gap="xxsmall">
                    <Text size="14px" weight={'bolder'} style={{ opacity: props.enabled ? undefined : 0.5 }}>
                        Items to Include
                    </Text>
                    <CheckBox
                        disabled={props.dataLoading || !props.enabled}
                        label="Group by item type (e.g. bugs, tasks, docs)"
                        checked={customSettings.alsoGroupByItemType}
                        color={Colors.accent_3}
                        onChange={(event) => {
                            setCustomSettings((prev) => {
                                return {
                                    ...prev,
                                    alsoGroupByItemType: event.target.checked,
                                };
                            });
                        }}
                    />
                    <CheckBox
                        label="Show discussions"
                        disabled={props.dataLoading || !props.enabled}
                        checked={customSettings.showCommentsSummary}
                        color={Colors.accent_3}
                        onChange={(event) => {
                            setCustomSettings((prev) => {
                                return {
                                    ...prev,
                                    showCommentsSummary: event.target.checked,
                                };
                            });
                        }}
                    />
                </Box>
                {!settingsAreEqual && (
                    <Box direction="row">
                        <CtaButtonSpinnerV2
                            disabled={props.dataLoading || isEqual(customSettings, props.customSettings)}
                            style={{ width: '100px' }}
                            label="Apply"
                            onClick={async () => {
                                props.onCustomSettingsChange({
                                    levelOfDetail: customSettings.levelOfDetail,
                                    showCommentsSummary: customSettings.showCommentsSummary,
                                    summaryFormat: customSettings.summaryFormat,
                                    alsoGroupByItemType: customSettings.alsoGroupByItemType,
                                });
                            }}
                            showSpinner={props.dataLoading}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export function SummaryStyleButton(props: {
    customSettings: SummarizationSettingsInput;
    onCustomSettingsChange: (customSettings: SummarizationSettingsInput) => void;
    timelineLoading: boolean;
    summaryLoading: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [customizeEnabled, setCustomizeEnabled] = useState(true);

    const styleFromSettings = useMemo(() => {
        if (areSettingsEqual(props.customSettings, getSummarySettingsForSummaryStyle(SummarizationStyle.Highlights))) {
            return SummarizationStyle.Highlights;
        }
        if (
            areSettingsEqual(props.customSettings, getSummarySettingsForSummaryStyle(SummarizationStyle.ReleaseNotes))
        ) {
            return SummarizationStyle.ReleaseNotes;
        }
        return undefined;
    }, [props.customSettings]);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={true}
                icon={Palette}
                label="Summary Style"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.timelineLoading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', right: 'right' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        width={{ width: '500px', max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                        gap="xsmall"
                    >
                        <Box direction="row" justify="between" fill="horizontal">
                            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                Summary Style
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                                alignSelf="end"
                            />
                        </Box>
                        <Grid columns={{ count: 2, size: '170px' }} gap="xsmall">
                            {SummarizationStyleArray.map((style, si) => (
                                <CtaTextButtonSpinner
                                    key={`style-${si}`}
                                    label={getLabelForSummarizationStyle(style)}
                                    getBoxProps={() => {
                                        return {
                                            pad: { horizontal: 'xsmall' },
                                            border: {
                                                color: Colors.accent_3,
                                                size: '1px',
                                            },
                                            height: '25px',
                                            justify: 'center',
                                            round: '8px',
                                            background: {
                                                color:
                                                    styleFromSettings === style
                                                        ? Colors.background_back
                                                        : Colors.background_front,
                                            },
                                            elevation: 'xsmall',
                                        };
                                    }}
                                    getTextProps={(hover) => {
                                        return {
                                            color: hover
                                                ? Colors.brand
                                                : styleFromSettings === style
                                                ? Colors.black
                                                : Colors.dark_6,
                                            size: '14px',
                                        };
                                    }}
                                    onClick={async () => {
                                        props.onCustomSettingsChange(getSummarySettingsForSummaryStyle(style));
                                    }}
                                />
                            ))}
                        </Grid>
                        <CustomSummarizationSettingsView
                            enabled={customizeEnabled}
                            dataLoading={props.timelineLoading || props.summaryLoading}
                            customSettings={props.customSettings}
                            onCustomSettingsChange={props.onCustomSettingsChange}
                            onEnabledChange={setCustomizeEnabled}
                            key={styleFromSettings}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
