// Copyright 2021
// ThatWorks.xyz Limited

import {
    ActivityItemFilterOperator,
    ActivityItemFilterOutput,
    ActivityItemFiltersInput,
    ActivityItemPropertyType,
    ActorFilterInput,
    ActorFilterOutput,
    ActorType,
    ChangeActionType,
    ChangeActionTypeFilterInput,
    ChangeActionTypeFilterOutput,
    ChangeType,
    ChangeTypeFilterInput,
    ChangeTypeFilterOutput,
    ConnectorItemTypeFilterInput,
    ConnectorItemTypeFilterOutput,
    ItemPropertyFilterOutput,
    ItemType,
    ItemTypeFilterInput,
    ItemTypeFilterOutput,
    PropertyFieldFilterEmptyOutput,
    PropertyFieldFilterOutput,
    PropertyFieldFilterRegexOutput,
    PropertyFieldValueInput,
    StatusCategory,
    TimelineDateType,
} from '../../../../../__generated__/graphql';
import { isTimePeriodOption } from './filter-toolbar-button/DateInput';
import { Operator, Property, PropertyFilterGroup, PropertyFilterSelection } from './filter-toolbar-button/helpers';
import {
    DatePreset,
    datePresetToDays,
    TimelineDateSelection,
    timelineDateTypeToDatePreset,
} from './timeline-date-selection';

export const PROPERTY_FILTER_OPTIONS = [
    Property.Assignee,
    Property.Description,
    Property.Priority,
    Property.Status,
    Property.StartDate,
    Property.EndDate,
    Property.StatusCategory,
    Property.Tag,
];
const ITEM_TYPES_OPTIONS = [Property.TypeOfObject];
const CONNECTOR_ITEM_TYPES_OPTIONS = [Property.TypeOfItem];
const CHANGE_TYPE_OPTIONS = [Property.Changes];
const CHANGE_ACTION_TYPE_OPTIONS = [Property.Action];
const UPDATED_BY_TYPE_OPTIONS = [Property.UpdatesBy];
const ROOT_VALUE_OPERATORS: (Operator | undefined)[] = [Operator.Empty, Operator.Regex];

export function isCustomProperty(property: string): boolean {
    // If the property isn't pre-defined then it is a custom value
    return !Object.values<string>(Property).includes(property);
}

function filterOptions(
    propertyFilters: PropertyFilterSelection[],
    options: { type: 'predefined_properties'; props: Property[] } | { type: 'custom_properties' },
): PropertyFilterSelection[] {
    return propertyFilters.filter((filter) => {
        let propsOk = false;
        if (options.type === 'predefined_properties') {
            propsOk =
                filter.property !== undefined &&
                !isCustomProperty(filter.property) &&
                options.props.includes(filter.property as Property);
        } else if (options.type === 'custom_properties') {
            propsOk = filter.property !== undefined && filter.property.length > 0 && isCustomProperty(filter.property);
        }

        return filter.operator && filter.value && filter.property && propsOk;
    });
}

export function getItemTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ItemTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: ITEM_TYPES_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the item types
    const itemTypes: ItemType[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): ItemType[] => f.value.split(',') as ItemType[],
    );

    // Return
    return { in: [...new Set(itemTypes)] };
}

export function getPropertyFiltersFromItemTypes(
    itemTypes: ItemTypeFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate that we have types present
    if (!itemTypes?.in) {
        return undefined;
    }

    // Return
    return {
        property: Property.TypeOfObject,
        operator: Operator.In,
        value: itemTypes.in.join(),
    };
}

export function getConnectorItemTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ConnectorItemTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CONNECTOR_ITEM_TYPES_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ConnectorItemTypeFilterInput, filter) => {
            if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value as ChangeType;
            } else if (filter.operator === Operator.Regex) {
                accumulator.regex = filter.value;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value;
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(','));
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

export function getPropertyFiltersFromConnectorItemTypes(
    connectorItemTypes: ConnectorItemTypeFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that we have types present
    if (!connectorItemTypes?.in && !connectorItemTypes?.neq && !connectorItemTypes?.eq && !connectorItemTypes?.regex) {
        return undefined;
    }

    const selections: PropertyFilterSelection[] = [];
    if (connectorItemTypes.in) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.In,
            value: connectorItemTypes.in.join(),
        });
    }

    if (connectorItemTypes.eq) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Eq,
            value: connectorItemTypes.eq,
        });
    }

    if (connectorItemTypes.regex) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Regex,
            value: connectorItemTypes.regex,
        });
    }

    if (connectorItemTypes.neq) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Neq,
            value: connectorItemTypes.neq,
        });
    }

    // Return
    return selections;
}

export function getChangeTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
    datetime: TimelineDateSelection,
): ChangeTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CHANGE_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ChangeTypeFilterInput, filter) => {
            if (filter.operator === Operator.Empty) {
                // Parse the datetime
                let days: number = Number(datetime.customDaysStr);
                if (
                    [DatePreset.OneDay, DatePreset.OneWeek, DatePreset.OneMonth, DatePreset.ThreeMonths].includes(
                        datetime.preset,
                    )
                ) {
                    days = datePresetToDays(
                        datetime.preset as Exclude<
                            DatePreset,
                            DatePreset.Custom | DatePreset.Today | DatePreset.StartOfWeek | DatePreset.EndOfWeek
                        >,
                    );
                }

                // Add the since operator
                accumulator.since = {
                    changeCount: filter.value === 'true' ? { eq: 0 } : { neq: 0 },
                    dateQuery: {
                        type: TimelineDateType.RelativeDaysMinus,
                        relativeDays: days,
                        userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                };
            } else if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value as ChangeType;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value as ChangeType;
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(',') as ChangeType[]);
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

export function getPropertyFiltersFromChangeType(
    changeType: ChangeTypeFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that this field is filled
    const since = changeType?.since;
    const inList = changeType?.in || [];
    const neq = changeType?.neq;
    const eq = changeType?.eq;
    if (since == null && inList.length === 0 && neq == null && eq == null) {
        return undefined;
    }

    // Initialise
    const selections: PropertyFilterSelection[] = [];

    // Since value
    if (since) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Empty,
            value: since.changeCount?.eq === 0 ? 'true' : 'false',
        });
    }

    // In
    if (inList && inList.length > 0) {
        selections.push({
            property: Property.Changes,
            operator: Operator.In,
            value: inList.join(),
        });
    }

    // Eq
    if (eq) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Eq,
            value: eq,
        });
    }

    // Neq
    if (neq) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Neq,
            value: neq,
        });
    }

    // Return
    return selections;
}

function getChangeActionTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ChangeActionTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CHANGE_ACTION_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the types
    const actionTypes: ChangeActionType[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): ChangeActionType[] => f.value.split(',') as ChangeActionType[],
    );

    // Return
    return { in: [...new Set(actionTypes)] };
}

function getPropertyFiltersFromChangeActionTypes(
    changeActionType: ChangeActionTypeFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate if the change action type is filled
    if (changeActionType == null || changeActionType.in == null || changeActionType.in.length === 0) {
        return undefined;
    }

    // Return
    return {
        property: Property.Action,
        operator: Operator.In,
        value: changeActionType.in.join(),
    };
}

function getTimelineDateTypeFromValue(
    value: Exclude<
        DatePreset,
        DatePreset.Custom | DatePreset.OneDay | DatePreset.OneWeek | DatePreset.OneMonth | DatePreset.ThreeMonths
    >,
): TimelineDateType {
    switch (value) {
        case DatePreset.Today:
            return TimelineDateType.Today;
        case DatePreset.StartOfWeek:
            return TimelineDateType.StartOfWeek;
        case DatePreset.EndOfWeek:
            return TimelineDateType.EndOfWeek;
    }
}

export function getSanitizedPropertyFilters(propertyFilterGroup: PropertyFilterGroup): {
    [operator: string]:
        | {
              value: string | boolean | PropertyFieldValueInput;
              property: string | undefined;
          }
        | {
              value: string | boolean | PropertyFieldValueInput;
              property: string | undefined;
          }[];
}[] {
    // Filter the property filter group
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: PROPERTY_FILTER_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return [];
    }

    // Map the filters and return
    return propertyFilters.map((f) => {
        let value: string | boolean | PropertyFieldValueInput = f.value;
        let property: string | undefined = f.property;

        // String or boolean values
        if (ROOT_VALUE_OPERATORS.includes(f.operator)) {
            // Map empty operator value to boolean
            if (f.operator === Operator.Empty) {
                value = f.value === 'true';
            }
        }
        // PropertyFieldValueInput values
        else {
            // End date
            if (f.property === Property.StartDate || f.property === Property.EndDate) {
                if (isTimePeriodOption(f.value)) {
                    value = {
                        dateQuery: {
                            type: getTimelineDateTypeFromValue(
                                f.value as Exclude<
                                    DatePreset,
                                    | DatePreset.Custom
                                    | DatePreset.OneDay
                                    | DatePreset.OneWeek
                                    | DatePreset.OneMonth
                                    | DatePreset.ThreeMonths
                                >,
                            ),
                            userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    };
                } else {
                    const relativeDays = Number(f.value);
                    value = {
                        dateQuery: {
                            type:
                                relativeDays < 0
                                    ? TimelineDateType.RelativeDaysMinus
                                    : TimelineDateType.RelativeDaysPlus,
                            relativeDays: Math.abs(relativeDays),
                            userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    };
                }
            }
            // Status Category
            else if (f.property === Property.StatusCategory) {
                property = Property.Status;
                value = {
                    statusCategory: f.value as StatusCategory,
                };
            }
            // Other properties
            else {
                // In operator
                if (f.operator === Operator.In) {
                    const values = f.value.split(',').map((v) => ({ value: { rawValue: v }, property: property }));
                    return { [f.operator as Operator]: values };
                }

                // Other operators
                value = { rawValue: f.value };
            }
        }

        // Return
        return { [f.operator as Operator]: { value: value, property: property } };
    });
}

export function getPropertyFiltersFromProperties(
    properties: ItemPropertyFilterOutput[] | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that we have properties present
    if (!properties || properties.length === 0) {
        return undefined;
    }

    // Return
    return properties.map((property) => {
        // Initialize selection
        const selection: PropertyFilterSelection = {
            property: undefined,
            operator: undefined,
            value: '',
        };

        // Iterate over all the operators in the property
        for (let key in property) {
            let propertyField:
                | PropertyFieldFilterEmptyOutput
                | PropertyFieldFilterOutput
                | PropertyFieldFilterRegexOutput;

            // Continue to the next key if the property field is empty
            if (property[key as Operator] == null) {
                continue;
            }

            if (key === Operator.In) {
                // Validate the property fields
                const propertyFields = property[key as Operator] as PropertyFieldFilterOutput[];
                const propertiesSet = new Set(propertyFields.map((p) => p.property));
                const valuesSet = new Set(
                    propertyFields.flatMap((p) => (p.value.rawValue != null ? [p.value.rawValue] : [])),
                );

                // If we have more than one property or if we don't have values, continue to the next key
                if (propertiesSet.size !== 1 || valuesSet.size === 0) {
                    continue;
                }

                // Get property
                const propertyIn = propertiesSet.values().next().value as ActivityItemPropertyType;

                // Get value
                const valueIn = { rawValue: [...valuesSet].join(',') };

                propertyField = { property: propertyIn, value: valueIn };
            } else {
                // Get the property field
                propertyField = property[key as Operator] as
                    | PropertyFieldFilterEmptyOutput
                    | PropertyFieldFilterOutput
                    | PropertyFieldFilterRegexOutput;
            }

            // Validate if the filters support the property received
            const propertyOptions = Object.values(Property) as string[];
            if (!propertyOptions.includes(propertyField.property)) {
                continue;
            }

            // Update operator
            selection.operator = key as Operator;

            // Update property
            selection.property = propertyField.property as string as Property;

            // Update empty operator value
            if (typeof propertyField.value === 'boolean') {
                selection.value = propertyField.value.toString();
            }
            // Update regex operator value
            else if (typeof propertyField.value === 'string') {
                selection.value = propertyField.value;
            }
            // Update PropertyFieldValueOutput value
            else {
                const dateQuery = propertyField.value.dateQuery;
                const statusCategory = propertyField.value.statusCategory;

                // End date
                if (
                    (selection.property === Property.EndDate || selection.property === Property.StartDate) &&
                    dateQuery
                ) {
                    // Relative days
                    if (dateQuery.relativeDays != null) {
                        selection.value = `${dateQuery.relativeDays}`;
                        if (dateQuery.type === TimelineDateType.RelativeDaysMinus) {
                            selection.value = `-${selection.value}`;
                        }
                    }
                    // Date preset option
                    else {
                        selection.value = timelineDateTypeToDatePreset(dateQuery.type);
                    }
                }
                // Status category
                else if (selection.property === Property.Status && statusCategory) {
                    selection.property = Property.StatusCategory;
                    selection.value = statusCategory;
                }
                // Other properties with regex values
                else {
                    selection.value = propertyField.value.rawValue ?? '';
                }
            }

            // If we are here, we have updated the selection and we can exit the loop
            break;
        }

        // Return
        return selection;
    });
}

function getActorsFromPropertyFilters(propertyFilterGroup: PropertyFilterGroup): ActorFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: UPDATED_BY_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the types
    const actorType: ActorType[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): ActorType[] => f.value.split(',') as ActorType[],
    );

    // Return
    return { in: [...new Set(actorType)] };
}

function getPropertyFiltersFromActors(
    actors: ActorFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate that we have actors present
    if (actors == null || actors.in == null || actors.in.length === 0) {
        return undefined;
    }

    // Return
    return {
        property: Property.UpdatesBy,
        operator: Operator.In,
        value: actors.in.join(),
    };
}

function getCustomNameValueProperties(
    propertyFilterGroup: PropertyFilterGroup,
): Record<string, { value: string | boolean; name: string }>[] | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, { type: 'custom_properties' });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    const res: Record<string, { value: string | boolean; name: string }>[] = [];

    // Map the filters and return
    propertyFilters.forEach((f) => {
        let value: string | boolean = f.value;
        let property: string | undefined = f.property;
        // Map empty operator value to boolean
        if (f.operator === Operator.Empty) {
            value = f.value === 'true';
        }

        if (!property || value == null) {
            return;
        }

        res.push({ [f.operator as string]: { value: value, name: property } });
    });

    return res;
}

export function getActivityFilters(
    propertyFiltersGroups: PropertyFilterGroup[],
    filterOperator: ActivityItemFilterOperator,
    datetime: TimelineDateSelection,
): ActivityItemFiltersInput {
    // Initialize the filters
    const filters: ActivityItemFiltersInput = {
        filters: [],
        operator: filterOperator,
    };

    // Iterate over the property filters groups
    filters.filters = propertyFiltersGroups.flatMap((propertyFilterGroup) => {
        // Item types
        const itemTypes = getItemTypesFromPropertyFilters(propertyFilterGroup);

        // Connector item types
        const connectorItemType = getConnectorItemTypesFromPropertyFilters(propertyFilterGroup);

        // Properties
        const properties = getSanitizedPropertyFilters(propertyFilterGroup);

        // Custom name value properties
        const propertiesNameValue = getCustomNameValueProperties(propertyFilterGroup);

        // Change type
        const changeType = getChangeTypesFromPropertyFilters(propertyFilterGroup, datetime);

        // Change action type
        const changeActionType = getChangeActionTypesFromPropertyFilters(propertyFilterGroup);

        // actors
        const actors = getActorsFromPropertyFilters(propertyFilterGroup);

        // Check if we should return the filter
        if (
            itemTypes ||
            properties.length > 0 ||
            changeType ||
            changeActionType ||
            actors ||
            propertiesNameValue ||
            connectorItemType
        ) {
            const r: ActivityItemFiltersInput['filters'] = [
                {
                    type: itemTypes,
                    properties: properties,
                    changeType: changeType,
                    action: changeActionType,
                    actors,
                    propertiesNameValue,
                    connectorItemType,
                },
            ];
            return r;
        }

        // Ignore the filter
        return [];
    });

    // Return the filters
    return filters;
}

export function getPropertyFilters(filters: ActivityItemFilterOutput[]): PropertyFilterGroup[] {
    // Initialize the property filters groups
    const propertyFiltersGroups: PropertyFilterGroup[] = [];

    // Iterate over the filters
    filters.forEach((filter) => {
        // Initialize property filters
        const propertyFilters: PropertyFilterSelection[] = [];

        // Item types
        const typeFilter = getPropertyFiltersFromItemTypes(filter.type);
        if (typeFilter) {
            propertyFilters.push(typeFilter);
        }

        // Connector item types
        const connectorItemType = getPropertyFiltersFromConnectorItemTypes(filter.connectorItemType);
        if (connectorItemType && connectorItemType.length > 0) {
            propertyFilters.push(...connectorItemType);
        }

        // Properties
        const propertyFilter = getPropertyFiltersFromProperties(filter.properties);
        if (propertyFilter && propertyFilter.length > 0) {
            propertyFilters.push(...propertyFilter);
        }

        // Change type
        const changeType = getPropertyFiltersFromChangeType(filter.changeType);
        if (changeType && changeType.length > 0) {
            propertyFilters.push(...changeType);
        }

        // Change action type
        const changeActionType = getPropertyFiltersFromChangeActionTypes(filter.action);
        if (changeActionType) {
            propertyFilters.push(changeActionType);
        }

        // Actors
        const actors = getPropertyFiltersFromActors(filter.actors);
        if (actors) {
            propertyFilters.push(actors);
        }

        // Add the property filters to the property filters groups
        propertyFiltersGroups.push({ propertyFilters: propertyFilters });
    });

    // Return the property filters groups
    return propertyFiltersGroups;
}
