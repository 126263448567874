// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Drop, Grid, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useRef, useState } from 'react';
import { GroupSettingsInput, ItemGroupType } from '../../../../../__generated__/graphql';
import { CtaTextButtonSpinner } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { Folder } from '../../../../../icons/Folder';
import { FontFamily } from '../../../../../theme';

export const DEFAULT_GROUP_SETTINGS: GroupSettingsInput = {
    groupType: ItemGroupType.None,
};

const GroupTypeArray = Array.from([
    ItemGroupType.None,
    ItemGroupType.Assignee,
    ItemGroupType.Parent,
    ItemGroupType.Status,
    ItemGroupType.People,
    ItemGroupType.StatusLifecycle,
]);

function getLabelForItemGroupType(groupType: ItemGroupType): string {
    switch (groupType) {
        case ItemGroupType.Assignee:
            return 'Assignee';
        case ItemGroupType.Parent:
            return 'Parent';
        case ItemGroupType.Status:
            return 'Status';
        case ItemGroupType.People:
            return 'People';
        case ItemGroupType.None:
            return 'None';
        case ItemGroupType.StatusLifecycle:
            return 'Status Lifecycle';
    }
}

export function GroupToolbarButton(props: {
    group: GroupSettingsInput;
    onChange: (group: ItemGroupType) => void;
    timelineLoading: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={buttonActive || props.group.groupType !== ItemGroupType.None}
                icon={Folder}
                label="Group By"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.timelineLoading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        width={{ max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                        align="center"
                        gap="xsmall"
                    >
                        <Box direction="row" justify="between" fill="horizontal">
                            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                Group By
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                                alignSelf="end"
                            />
                        </Box>
                        <Grid columns={{ count: 3, size: '170px' }} gap="xsmall">
                            {GroupTypeArray.map((group, gi) => (
                                <CtaTextButtonSpinner
                                    key={`group-${gi}`}
                                    label={getLabelForItemGroupType(group)}
                                    getBoxProps={() => {
                                        return {
                                            pad: { horizontal: 'xsmall' },
                                            border: {
                                                color: Colors.accent_3,
                                                size: '1px',
                                            },
                                            height: '25px',
                                            justify: 'center',
                                            round: '8px',
                                            background: {
                                                color:
                                                    props.group.groupType === group
                                                        ? Colors.background_back
                                                        : Colors.background_front,
                                            },
                                            elevation: 'xsmall',
                                        };
                                    }}
                                    getTextProps={(hover) => {
                                        return {
                                            color: hover
                                                ? Colors.brand
                                                : props.group.groupType === group
                                                ? Colors.black
                                                : Colors.dark_6,
                                            size: '14px',
                                        };
                                    }}
                                    onClick={async () => {
                                        props.onChange(group);
                                    }}
                                />
                            ))}
                        </Grid>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
