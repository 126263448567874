// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Drop, Select } from 'grommet';
import { Close, Filter } from 'grommet-icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { gql } from '../../../../../../__generated__';
import { ActivityItemFilterOperator } from '../../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../../components/prosemirror/ToolbarButton';
import { useTelemetryContext } from '../../../../../../components/TelemetryContext';
import { TwHeading } from '../../../../../../components/TwHeading';
import { useUserStateContext } from '../../../../../../components/UserContext';
import { AddGroupButton } from './AddGroupButton';
import { FilterGroup } from './FilterGroup';
import { DEFAULT_PROPERTY_FILTERS, Operator, Property, PropertyFilterGroup } from './helpers';

const GET_TIMELINE_PROPERTIES = gql(/* GraphQL */ `
    query TimelineProperties($timelineId: ID!) {
        timelineProperties(timelineId: $timelineId) {
            assignees
            priorities
            statuses
            connectorItemTypes
            tags
        }
    }
`);

export const Listing = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export function FilterToolbarButton(props: {
    onChangePropertyFilters: (filters: PropertyFilterGroup[]) => void;
    onChangeFilterOperator: (operator: ActivityItemFilterOperator) => void;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    timelineId: string | undefined;
    dataLoading: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const [propertiesOptions, setPropertiesOptions] = useState<typeof DEFAULT_PROPERTY_FILTERS>(
        structuredClone(DEFAULT_PROPERTY_FILTERS),
    );
    const ref = useRef<HTMLDivElement>(null);
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    // Valid filters flag
    const hasValidFilters = useMemo(
        () =>
            props.propertyFilterGroups.some((group) =>
                group.propertyFilters.some((f) => f.property && f.operator && f.value),
            ),
        [props.propertyFilterGroups],
    );

    // Property filter options
    const [getTimelineProperties] = useLazyQuery(GET_TIMELINE_PROPERTIES, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get timeline properties` });
            logger.error(error.message);
        },
    });
    useEffect(() => {
        // Check if we already have the timeline ID
        if (!props.timelineId) {
            return;
        }

        // Get the timeline properties
        getTimelineProperties({
            variables: { timelineId: props.timelineId },
            onCompleted: (data) => {
                // Deep copy the default filters
                const propertyFilters = structuredClone(DEFAULT_PROPERTY_FILTERS);

                // Update the default filters with the received options
                const { assignees, priorities, statuses, connectorItemTypes, tags } = data.timelineProperties;
                const mapping = [
                    {
                        property: Property.Assignee,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: assignees,
                    },
                    {
                        property: Property.Priority,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex],
                        receivedOptions: priorities,
                    },
                    {
                        property: Property.Status,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex],
                        receivedOptions: statuses,
                    },
                    {
                        property: Property.TypeOfItem,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: connectorItemTypes,
                    },
                    {
                        property: Property.Tag,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: tags,
                    },
                ];
                mapping.forEach(({ property, operators, receivedOptions }) => {
                    operators.forEach((operator) => {
                        let valueOptions = propertyFilters[property]?.operatorOptions[operator]?.valueOptions;
                        if (valueOptions) {
                            valueOptions.options = receivedOptions.map((v) => ({ label: v, value: v }));
                        }
                    });
                });

                // Set the property filters
                setPropertiesOptions(propertyFilters);
            },
        });
    }, [props.timelineId, getTimelineProperties]);

    return (
        <Box ref={ref} margin={{ left: '32px' }}>
            <ToolbarButton
                active={buttonActive || hasValidFilters}
                icon={Filter}
                label="Customize query"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                iconSize="14px"
                textProps={{ size: '12px' }}
                boxProps={{ gap: 'xxsmall' }}
                disabled={props.dataLoading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        height={{ min: '86px', max: '50vh' }}
                        width={{ min: '350px', width: '500px', max: '50vw' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'25px'}
                        elevation="xsmall"
                        gap="xxsmall"
                        overflow={{ vertical: 'scroll' }}
                    >
                        {/* Operator and Close button */}
                        <Box direction="row" gap="xxsmall" align="center" justify="between" height={{ min: '32px' }}>
                            <Box
                                border={{ color: Colors.accent_3, size: '1px' }}
                                round="10px"
                                height="32px"
                                direction="row"
                                align="center"
                                justify="center"
                                width="min-content"
                                pad={{ left: 'xxsmall' }}
                            >
                                <TwHeading level={6} style={{ whiteSpace: 'nowrap' }}>
                                    Operator:
                                </TwHeading>
                                <Select
                                    width="50px"
                                    plain
                                    focusIndicator
                                    icon={false}
                                    size="small"
                                    options={[
                                        { label: 'OR', value: ActivityItemFilterOperator.Or },
                                        { label: 'AND', value: ActivityItemFilterOperator.And },
                                    ]}
                                    valueKey="value"
                                    labelKey="label"
                                    value={props.filtersOperator}
                                    onChange={({ option }) => props.onChangeFilterOperator(option.value)}
                                />
                            </Box>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                            />
                        </Box>
                        {/* Filter groups */}
                        <Listing>
                            {props.propertyFilterGroups.map((propertyFilterGroup, index) => (
                                <Box key={`group-${index}`} gap={'8px'}>
                                    <FilterGroup
                                        groupIndex={index + 1}
                                        propertyFilterGroup={propertyFilterGroup}
                                        propertiesOptions={propertiesOptions}
                                        onChange={(group) => {
                                            const newSelection = [...props.propertyFilterGroups];
                                            newSelection[index] = group;
                                            props.onChangePropertyFilters(newSelection);
                                        }}
                                        onDelete={() => {
                                            const newSelection = [...props.propertyFilterGroups];
                                            newSelection.splice(index, 1);
                                            props.onChangePropertyFilters(newSelection);
                                        }}
                                    />
                                    {index !== props.propertyFilterGroups.length - 1 && (
                                        <TwHeading level={6}>{props.filtersOperator.toLowerCase()}</TwHeading>
                                    )}
                                </Box>
                            ))}
                        </Listing>
                        {/* Add group button */}
                        <AddGroupButton
                            onChange={props.onChangePropertyFilters}
                            propertyFilterGroups={props.propertyFilterGroups}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
